.Ap {
  text-align: center;
  padding: 20px;
}

.Ap-header {
  background-color: #282c34;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.access-input {
  padding: 10px;
  margin-top: 20px;
  width: 300px;
  max-width: 80%;
  border: 2px solid #61dafb;
  border-radius: 5px;
  font-size: 1em;
  margin-bottom: 70px;
}

.submit-button {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #21a1f1;
}
