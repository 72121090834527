:root {
  --bg-color: #f9f9f9;
  --card-bg-color: #fff;
  --text-color: #333;
  --hover-scale: 1.03; /* How much the card scales on hover */
  --transition-speed: 0.4s;
}

.team {
   align-items: center;
  justify-content: center;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 2rem;
  padding: 2rem;
  justify-content: space-around;
}

.team-card {
  position: relative;
  width: 250px;
  height: 270px;
  border-radius: 20px;
  background-color: var(--card-bg-color);
  /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1); */
  transition: transform var(--transition-speed),
    box-shadow var(--transition-speed);
  transform-style: preserve-3d; /* Enable 3D transformations */
  cursor: pointer;
  margin-left: 30px;
  /* border: 3px solid black; */
}

.team-card img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  border-radius: 20px 20px 0 0;
}

.team-card h2 {
  margin: 20px;
  text-align: center;
  color: var(--text-color);
  transition: color var(--transition-speed);
}

.team-card:hover {
  /* transform: translateY(-20px) scale(var(--hover-scale)); */
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
}

/* Adds a nice hovering effect */
.team-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  opacity: 0;
  transition: opacity var(--transition-speed);
}

.team-card:hover::after {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .team-container {
    justify-content: center;
  }
}
