.captainsContainer,.dd ,#teamshow{
  background-image: url("https://images.unsplash.com/photo-1625401586060-f12be3d7cc57?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y3JpY2tldCUyMHN0YWRpdW18ZW58MHx8MHx8fDA%3D");
  background-attachment: fixed;
  background-size: cover;
  /* This property doesn't exist, so we achieve the effect in another way */
  position: relative;
}

.captainsContaine {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.al {
  text-align: center;
  padding: 10px;
  color: white;
}

.captainsContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with lowered opacity */
  z-index: -1;
}

.captainCard {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 1; /* Ensures content is above the overlay */
}

.captainCard img {
  width: 100%;
  height: auto;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .captainsContainer {
    grid-template-columns: 1fr;
  }
}
