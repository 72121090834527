.TeamForm {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.TeamForm label {
  margin-top: 10px;
}

.TeamForm input[type="text"],
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.TeamForm button {
  padding: 10px;
  margin-bottom: 15px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.TeamForm button:hover {
  background-color: #2980b9;
}

@media screen and (max-width: 768px) {
  .TeamForm {
    padding: 15px;
  }

  .TeamForm input[type="text"],
  select {
    margin-bottom: 10px;
  }

  .TeamForm button {
    margin-bottom: 10px;
  }
}

.teamlogo {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  outline: none;
}

.player {
  width: 500px;
}

.teamim {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid white;
}

.updateteam {
  background-color: rgb(246, 214, 33);
  margin: 15px 5px;
  border-radius: 10%;
  border: 2px solid white;
}

.delete {
  border-radius: 10%;
  margin: 15px 5px;
  background-color: #ff5555;
  border: 2px solid white;
}

.teamcar {
  display: flex;
  justify-content: space-around;
  min-width: 80%;
  padding: 10px;
  border: 2px solid white;
  background-color: black;
}

.team-top {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.teamnam {
  width: 300px;
  overflow-x: auto;
  padding: 20px;
  color: white;
}

@media screen and (max-width: 475px) {
  .teamcar {
    min-width: 300px;
  }
}

.delete:hover,
.updateteam:hover {
  color: white;
}

#tab {
  margin: 0 10%;
}

.teamcardshow {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  border: 2px solid white;
  background-color: black;
  width: 50%;
  margin-left: 25%;
}

#teamid {
  margin-left: 45%;
}

@media screen and (max-width: 770px) {
  .teamcardshow {
    margin-left: 15%;
    width: 70%;
  }

  #teamid {
    margin-left: 40%;
  }
}

@media screen and (max-width: 550px) {
  .teamcardshow {
    width: 80%;
    margin-left: 10%;
  }
  .teamnam {
    width: 300px;
    white-space: nowrap;
    overflow: auto;
  }
}

#teamshow {
  text-align: center;
  background-color: #f4f4f4; /* Light grey background */
  padding: 20px;
  box-sizing: border-box;
  
}

#teamid {
  color: #2a71d0; /* Vibrant blue for the heading */
  margin-bottom: 30px;
}

/* Team Card Styles */
.teamcardshow {
  display: inline-block;
  background-color: #ffffff; /* White background for cards */
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for hover */
}

/* Image Styles */
.teamimg {
  width: 100%;
  height: 30vh;
  display: block;
  object-fit: contain;
}

/* Team Name Styles */
.teamname {
  color: white;
  padding: 20px 10px;
  background-color: rgb(4, 51, 76);
  text-transform: uppercase;
}

/* Hover Effects */
.teamcardshow:hover {
  transform: translateY(-5px); /* Slightly lift the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .teamcardshow {
    width: 90%; /* Full width for smaller screens */
    margin: 10px auto; /* Centering cards */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .teamcardshow {
    width: 45%; /* Adjust width for medium screens */
  }
}

@media (min-width: 1025px) {
  .teamcardshow {
    width: 30%; /* Adjust width for larger screens */
  }
}

/* TeamsUpdateMessage.css */

.update-message {
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  max-width: 400px;
  margin: 10px auto;
}

.update-message h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Optional: Hover effect */
.update-message:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 1);
}

.teans {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.teans span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
.teans em {
  font-style: normal;
  font-weight: 600;
}

.one h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}


.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-image {
  width: 100px;
  height: 100px;
  object-fit: cover; /* Ensures the image covers the area, adjust as needed */
}