/* TermsAndConditions.css */
.terms-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.h1 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.h2 {
  color: #0275d8;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.p {
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 15px;
}

.term:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .terms-container {
    margin: 10px;
    padding: 15px;
  }

  .h1 {
    font-size: 24px;
  }

  .h2 {
    font-size: 20px;
  }

  .p {
    font-size: 14px;
  }
}
