.galleryWrap {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0px 12px;
}

.single img {
  margin-bottom: 12px;

}

@media (max-width: 991px) {
  .galleryWrap {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .galleryWrap {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns in larger screens */
  gap: 20px;
  padding: 20px;
}

.image-card {
  position: relative;
  overflow: hidden;
}

.image-card img {
  width: 100%;
  height: auto;
}

.image-card:hover img {
  transform: scale(1.1);
}

.delete-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.delete-button:hover {
  opacity: 1;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  /* For tablets */
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  /* For mobile phones */
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100px;
}

.pagination button:hover {
  background-color: #e0e0e0;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Optional: Adjust button styles for smaller screens */
@media screen and (max-width: 768px) {
  .pagination button {
    padding: 6px 12px;
    margin: 0 3px;
  }
}
